import React from "react"
import { Helmet } from "react-helmet"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"

import Separator from "../../components/separator"
import Toggle from "../../components/toggle"
import Layout from "../../components/layout"
import ColoredHeader from "../../components/coloredHeader"
import FloatingSearch from "../../components/floatingSearch"

import atlas_lg_blackwhite from "../../../static/background_images/atlas_lg_blackwhite.jpg"

const Headquarters = () => (
  <div className="toggle-content">
    <p>
      1111 Bagby Street
      <br />
      Sky Lobby 2<br />
      Houston, TX 77002
    </p>
    <p>
      Phone:&nbsp;(713) 651-7000
      <br />
      Toll Free:&nbsp;(877) 363-3647
      <br />
      Fax: (713) 651-6995
    </p>
    <p>
      <b>Mailing:</b>
      <br />
      EOG Resources, Inc.
      <br />
      P.O. Box 4362
      <br />
      Houston, TX 77210-4362
    </p>
    <p>
      <b>Shipping:</b>
      <br />
      EOG Resources, Inc.
      <br />
      1111 Bagby Street
      <br />
      Sky Lobby 2<br />
      Houston, TX 77002
    </p>
  </div>
)

const BusinessDevelopment = () => (
  <div className="toggle-content">
    <h3>Corporate</h3>
    <Separator width="100%" paddingBottom="5px" />
    <div className="cols-2">
      <b>Houston, Texas</b>
      <br />
      <a href="mailto:mark_castiglione@eogresources.com">Mark Castiglione</a>
      <br />
      Sr. Director, Business Development
      <br />
      Phone: (281) 782-4425
      <br />
    </div>
    <h3>Areas of Operation</h3>
    <Separator width="100%" paddingBottom="5px" />
    <div className="cols-2">
      <p>
        <b>Texas Gulf</b>
        <br />
        Corpus Christi, Texas
        <br />
        <a href="mailto:palmer_simpson@eogresources.com">Palmer Simpson</a>
        <br />
        Division Land Manager
        <br />
        Phone: (361) 902-2871
      </p>
      <p>
        <b>Eagle Ford</b>
        <br />
        San Antonio, Texas
        <br />
        <a href="mailto:nathan_stephenson@eogresources.com">
          Nathan Stephenson
        </a>
        <br />
        Division Land Manager
        <br />
        Phone: (210) 403-7752
      </p>
      <p>
        <b>North and East Texas/Eastern United States</b>
        <br />
        Fort Worth, Texas
        <br />
        <a href="mailto:Wendy_Dalton@eogresources.com">Wendy Dalton</a>
        <br />
        Division Land Manager
        <br />
        Phone: (817) 806-0766
      </p>
      <p>
        <b>Permian Basin/Delaware Basin</b>
        <br />
        Midland, Texas
        <br />
        <a href="mailto:Matt_Smith@eogresources.com">Matt Smith</a>
        <br />
        Division Land Manager
        <br />
        Phone: (432) 686-3684
      </p>
      <p>
        <b>Rocky Mountain Area</b>
        <br />
        Denver, Colorado
        <br />
        <a href="mailto:Paul_Boland@eogresources.com">Paul Boland</a>
        <br />
        Division Land Manager
        <br />
        Phone: (303) 262-9929
      </p>
      <p>
        <b>Mid-Continent</b>
        <br />
        Oklahoma City, Oklahoma
        <br />
        <a href="mailto:Chuck_Walton@eogresources.com">Chuck Walton</a>
        <br />
        Division Land Manager
        <br />
        Phone: (405) 246-3248
      </p>
      <p>
        <b>International</b>
        <br />
        Houston, Texas
        <br />
        <a href="mailto:joe_korenek@eogresources.com">Joe Korenek</a>
        <br />
        Vice President and General Manager
        <br />
        Phone: (832) 366-9210
      </p>
      <p>
        <b>Sand Facilities</b>
        <br />
        San Antonio, Texas
        <br />
        <a href="mailto:Bobby_Sanders@eogresources.com">Bobby Sanders</a>
        <br />
        Director, Shared Services
        <br />
        Phone: (210) 403-7804
      </p>
    </div>
  </div>
)

const HumanResources = () => (
  <div className="toggle-content">
    <p>
      <strong>
        Mailing:
        <br />
      </strong>
      EOG Resources, Inc.
      <br />
      P.O. Box 4362
      <br />
      Houston, TX 77210-4362
      <br />
      Attn: Human Resources
    </p>
    <div class="columns-1">
      <p>
        <strong>Shipping:</strong>
        <br />
        EOG Resources, Inc.
        <br />
        1111 Bagby Street
        <br />
        Sky Lobby 2<br />
        Houston, TX 77002
        <br />
        Attn: Human Resources
      </p>
      <p>
        <strong>
          FAX:
          <br />
        </strong>
        (713) 651-6995
      </p>
    </div>
    <p>
      For available positions, please refer to our&nbsp;
      <a
        href="https://careers.eogresources.com/process_jobsearch.asp"
        target="_blank"
        rel="noreferrer"
      >
        current list of available positions
      </a>
      .
    </p>
  </div>
)

const InvestorsMedia = () => (
  <div className="toggle-content">
    <h3>Investor Relations</h3>
    <Separator width="100%" paddingBottom="5px" />
    <p>
      <strong>Phone:&nbsp;</strong>(713) 651-7000
      <br />
      <strong>Toll Free:</strong>&nbsp;(877) 363-3647
      <br />
      <strong>E-mail:</strong>&nbsp;
      <a href="mailto:EOG_IR@eogresources.com">EOG_IR@eogresources.com</a>
    </p>
    <p>
      <strong>Mailing:</strong>
      <br />
      EOG Resources, Inc.
      <br />
      P.O. Box 4362
      <br />
      Houston, TX 77210-4362
      <br />
      Attn: Investor Relations
    </p>
    <p>
      <strong>Shipping:</strong>
      <br />
      EOG Resources, Inc.
      <br />
      1111 Bagby Street
      <br />
      Sky Lobby 2<br />
      Houston, TX 77002
      <br />
      Attn: Investor Relations
    </p>
    <h3>Media Inquiries</h3>
    <Separator width="100%" paddingBottom="5px" />
    <p>
      For media inquiries, please contact&nbsp;
      <a href="mailto:media@eogresources.com">media@eogresources.com</a>
      &nbsp;or (832) 366-9300.
    </p>
    <p>
      For Trinidad-specific media inquiries, please contact{" "}
      <a href="mailto:tt_media@eogresources.com">tt_media@eogresources.com</a>{" "}
      or (868) 822-5604.
    </p>
  </div>
)

const InterestOwners = () => (
  <div className="toggle-content">
    <h3>Owner Relations</h3>
    <Separator width="100%" paddingBottom="5px" />
    <p>
      The Owner Relations Team is here to answer any questions concerning
      ownership, division orders, lease and revenue payments. All calls are
      logged and returned typically within two working days.
    </p>
    <p>(833) 430-2550</p>
    <p>
      <a href="mailto:LandAdm_CallCenter@eogresources.com">
        landadm_callcenter@eogresources.com
      </a>
      .&nbsp;&nbsp;
    </p>
    <h3>Direct Deposit (ACH)</h3>
    <Separator width="100%" paddingBottom="5px" />
    <p>To set up Direct Deposit or make changes</p>
    <p>(877) 363-3647; option 3 then 2</p>
    <h3>EnergyLink website (Enverus)</h3>
    <Separator width="100%" paddingBottom="5px" />

    <p>
      For online account questions, password resets, or technical assistance,
      please contact EnergyLink by submitting a web form at:{" "}
      <a href="https://www.energylink.com/contact" target="_new">
        https://www.energylink.com/contact
      </a>
      .
    </p>

    <h3>1099 Contacts</h3>
    <Separator width="100%" paddingBottom="5px" />
    <p>
      (877) 642-1099 or&nbsp;
      <a href="mailto:EOGR1099@eogresources.com">EOGR1099@eogresources.com</a>
    </p>
    <h3>Working Interest Partner Inquiries</h3>
    <Separator width="100%" paddingBottom="5px" />
    <p>Questions regarding Joint Interest Billings (JIB)</p>
    <p>
      (713) 651-6553 or&nbsp;
      <a href="mailto:jibinquiry@eogresources.com">
        jibinquiry@eogresources.com
      </a>
    </p>
  </div>
)

const Offices = () => (
  <div className="cols-2">
    <p>
      <b>Houston, TX</b>&nbsp;
    </p>
    <p>
      <b>
        Corporate Headquarters
        <br />
      </b>
      1111 Bagby Street
      <br />
      Sky Lobby 2<br />
      Houston, TX 77002
    </p>
    <p>
      Phone: (713) 651-7000
      <br />
      Toll Free: (877) 363-3647
      <br />
      Fax: (713) 651-6995
    </p>
    <p>
      P.O. Box 4362
      <br />
      Houston, TX 77210-4362
    </p>
    <p>
      <strong>
        Corporate Headquarters and International
        <br />
      </strong>
      P.O. Box 4362
      <br />
      Houston, TX 77210-4362
      <br />
      Phone: (713) 651-7000
    </p>
    <p>
      <b>
        Corpus Christi, TX
        <br />
      </b>
      802 N Carancahua
      <br />
      Suite 1700
      <br />
      Corpus Christi, TX 78401
      <br />
      Phone: (361) 883-9231
      <br />
      Fax: (361) 902-2801
    </p>
    <p>
      <b>
        Denver, CO
        <br />
      </b>
      600 Seventeenth Street
      <br />
      Suite 1000N
      <br />
      Denver, CO 80202
      <br />
      Phone: (303) 572-9000
      <br />
      Fax: (303) 824-5400
    </p>
    <p>&nbsp;</p>

    <p>
      <b>
        Fort Worth, TX
        <br />
      </b>
      421 W. Third Street,
      <br />
      Suite 300
      <br />
      Fort Worth, TX 76102
      <br />
      Phone: (817) 339-9380
      <br />
      Fax: (817) 339-9327
    </p>
    <p>
      <b>
        Midland, TX
        <br />
      </b>
      5509 Champions Drive
      <br />
      Midland, TX 79706
      <br />
      Phone: (432) 686-3600
    </p>
    <p>
      <b>
        Oklahoma City, OK
        <br />
      </b>
      14701 Bogert Pkwy
      <br />
      Oklahoma City, OK 73134-2646
      <br />
      Phone: (405) 246-3100
    </p>
    <p>
      <b>
        San Antonio, TX
        <br />
      </b>
      19100 Ridgewood Parkway
      <br />
      Building 2<br />
      San Antonio, TX 78259
      <br />
      Phone: (210) 403-7700
    </p>
    <p>
      <b>Trinidad</b>
    </p>
    <p>
      <strong>EOG Resources Trinidad Limited</strong>
      <br />
      Briar Place
      <br />
      10-12 Sweet Briar Road
      <br />
      St Clair
      <br />
      Port of Spain
      <br />
      Trinidad, W. I.
      <br />
      Phone: (868) 622-8653
      <br />
      Fax: (868) 628-4215
    </p>
  </div>
)

const ShareHolderServices = () => (
  <div className="toggle-content">
    <p>Phone: (713) 651-7000</p>
    <p>
      Copies of the Annual Report and other printed materials are available upon
      request by calling (877) 363-3647 or by emailing{" "}
      <a href="mailto:EOG_IR@eogresources.com">EOG_IR@eogresources.com </a>– be
      sure to include your mailing address. You can also enroll online to{" "}
      <a
        href="https://enroll.icsdelivery.com/eog"
        target="_blank"
        rel="noreferrer"
      >
        receive your Proxy Statement and Annual Report electronically.
      </a>
    </p>
    <p>
      For information regarding specific shareholder questions, please contact
      Computershare:
    </p>
    <p>
      (877) 282-1168
      <br />
      Outside USA:&nbsp;(781) 575-2879
    </p>
    <p>
      <strong>Mailing:</strong>
      <br />
      Transfer Agent
      <br />
      Computershare Trust Company, N.A.
      <br />
      Attn: Shareholder Services
      <br />
      P.O. Box 43006
      <br />
      Providence, RI 02940-3006
    </p>
    <p>
      <a
        href="https://www-us.computershare.com/Default.asp?cc=US&amp;lang=EN"
        target="_blank"
        rel="noreferrer"
      >
        www.computershare.com
      </a>
    </p>
  </div>
)

export default function Contacts(props) {
  return (
    <Layout>
      <Helmet>
        <title>Contacts – EOG Resources, Inc.</title>
      </Helmet>
      <ColoredHeader
        title="COMPANY"
        header="Contacts"
        overlayColor1="#d50000"
        overlayColor2="#da3433"
        lineColor="#f95c4f"
        bgImageSRC={atlas_lg_blackwhite}
      />
      <div className="max-width-container absolute desktop-only">
        <FloatingSearch clear />
      </div>
      <section className="page contacts-page last-page">
        <SimpleTabs />
      </section>
    </Layout>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

function SimpleTabs() {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <div className="desktop-only contacts-tabs-container max-width-container">
        <Tabs
          value={value}
          onChange={handleChange}
          className="contacts-tabs-tabs"
        >
          <Tab className="c-tab" label="Headquarters" {...a11yProps(0)} />
          <Tab
            className="c-tab"
            label="Business Development"
            {...a11yProps(1)}
          />
          <Tab className="c-tab" label="Human Resources" {...a11yProps(2)} />
          <Tab
            className="c-tab"
            label="Investors and Media"
            {...a11yProps(3)}
          />
          <Tab className="c-tab" label="Interest Owners" {...a11yProps(4)} />
          <Tab className="c-tab" label="Offices" {...a11yProps(5)} />
          <Tab
            className="c-tab"
            label="Shareholder Services"
            {...a11yProps(6)}
          />
        </Tabs>
        <TabPanel className="contacts-tabpanel" value={value} index={0}>
          <Headquarters />
        </TabPanel>
        <TabPanel className="contacts-tabpanel" value={value} index={1}>
          <BusinessDevelopment />
        </TabPanel>
        <TabPanel className="contacts-tabpanel" value={value} index={2}>
          <HumanResources />
        </TabPanel>
        <TabPanel className="contacts-tabpanel" value={value} index={3}>
          <InvestorsMedia />
        </TabPanel>
        <TabPanel className="contacts-tabpanel" value={value} index={4}>
          <InterestOwners />
        </TabPanel>
        <TabPanel className="contacts-tabpanel" value={value} index={5}>
          <Offices />
        </TabPanel>
        <TabPanel className="contacts-tabpanel" value={value} index={6}>
          <ShareHolderServices />
        </TabPanel>
      </div>
      <div className="mobile-only">
        <Toggle
          className="first"
          title="Headquarters"
          expandIcon={
            <FontAwesomeIcon className="expanded-icon" icon={faMinus} />
          }
          contractedIcon={
            <FontAwesomeIcon
              className="contracted-icon"
              icon={faPlus}
              style={{ color: "black" }}
            />
          }
        >
          <Headquarters />
        </Toggle>
        <Toggle
          title="Business Development"
          expandIcon={
            <FontAwesomeIcon className="expanded-icon" icon={faMinus} />
          }
          contractedIcon={
            <FontAwesomeIcon
              className="contracted-icon"
              icon={faPlus}
              style={{ color: "black" }}
            />
          }
        >
          <BusinessDevelopment />
        </Toggle>
        <Toggle
          title="Human Resources"
          expandIcon={
            <FontAwesomeIcon className="expanded-icon" icon={faMinus} />
          }
          contractedIcon={
            <FontAwesomeIcon
              className="contracted-icon"
              icon={faPlus}
              style={{ color: "black" }}
            />
          }
        >
          <HumanResources />
        </Toggle>
        <Toggle
          title="Investors and Media"
          expandIcon={
            <FontAwesomeIcon className="expanded-icon" icon={faMinus} />
          }
          contractedIcon={
            <FontAwesomeIcon
              className="contracted-icon"
              icon={faPlus}
              style={{ color: "black" }}
            />
          }
        >
          <InvestorsMedia />
        </Toggle>
        <Toggle
          title="Interest Owners"
          expandIcon={
            <FontAwesomeIcon className="expanded-icon" icon={faMinus} />
          }
          contractedIcon={
            <FontAwesomeIcon
              className="contracted-icon"
              icon={faPlus}
              style={{ color: "black" }}
            />
          }
        >
          <InterestOwners />
        </Toggle>
        <Toggle
          title="Offices"
          expandIcon={
            <FontAwesomeIcon className="expanded-icon" icon={faMinus} />
          }
          contractedIcon={
            <FontAwesomeIcon
              className="contracted-icon"
              icon={faPlus}
              style={{ color: "black" }}
            />
          }
        >
          <Offices />
        </Toggle>
        <Toggle
          title="Shareholder Services"
          expandIcon={
            <FontAwesomeIcon className="expanded-icon" icon={faMinus} />
          }
          contractedIcon={
            <FontAwesomeIcon
              className="contracted-icon"
              icon={faPlus}
              style={{ color: "black" }}
            />
          }
        >
          <ShareHolderServices />
        </Toggle>
      </div>
    </>
  )
}
